import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import useGlobalState from "../../Store/configureStore";
import { useNavigate, useLocation } from "react-router-dom";
import "./appform.css";

const ApplicationForm = () => {
  const selectedSlot = useGlobalState((state) => state.selectedSlot);
  const saveState = useGlobalState((state) => state.saveState);
  const storeToFirebase = useGlobalState((state) => state.storeToFirebase);
  const [email, setEmail] = React.useState("");
  const [studentName, setStudentName] = React.useState("");
  const [parentName, setParentName] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [referral, setReferral] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [slot, setSlot] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [alertText, setAlertText] = React.useState({});
  const sessions = useGlobalState((state) => state.sessions);
  const [error, setError] = React.useState(false);
  const navigate = useNavigate();
  const sendEmail = useGlobalState((state) => state.sendEmail);

  React.useEffect(() => {
    if (selectedSlot && selectedSlot !== undefined) {
      if (selectedSlot.name !== "") {
        setSlot(
          selectedSlot.year +
            " , " +
            selectedSlot.name +
            " , " +
            selectedSlot.day +
            " , " +
            selectedSlot.timeSlot
        );
      } else {
        setSlot(
          selectedSlot.year +
            " , " +
            selectedSlot.day +
            " , " +
            selectedSlot.timeSlot
        );
      }
    } else {
      navigate("/browse");
    }
  });

  return (
    <Container style={{ marginTop: "0px" }}>
      <>
        <Modal
          show={show}
          onHide={() => {
            if (error) {
              setShow(false);
            } else {
              navigate("/");
            }
          }}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Enrollment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{JSON.stringify(alertText)}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                if (error) {
                  setShow(false);
                } else {
                  navigate("/");
                }
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Container style={{ marginTop: "60px" }}>
          <h1>Enrollment</h1>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Student Name</Form.Label>
              <Form.Control
                type="input"
                placeholder="Student Name"
                value={studentName}
                onChange={(e) => setStudentName(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Parent Name</Form.Label>
              <Form.Control
                type="input"
                placeholder="Parent Name"
                value={parentName}
                onChange={(e) => setParentName(e.target.value)}
              />
              <Form.Text>
                The way you want it to be displayed on the invoice
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Address (optional)</Form.Label>
              <Form.Control
                type="input"
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <Form.Text>
                Enter if you would like your address included on the invoice{" "}
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="input"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Session</Form.Label>
              <Form.Select
                aria-label="Default select example"
                value={slot}
                onChange={(e) => {
                  setSlot(e.target.value);
                  let selVal = e.target.value;
                  let selArray = selVal.split(" , ");
                  let tgtSlot = sessions.find(
                    (slot) =>
                      slot.name === selArray[0] &&
                      slot.day === selArray[1] &&
                      slot.timeSlot === selArray[3]
                  );
                  if (tgtSlot && tgtSlot !== undefined) {
                    saveState({ selectedSlot: tgtSlot });
                  }
                }}
              >
                <option>Choose Session</option>
                {sessions &&
                  sessions.map((slot) => {
                    if (slot.name && slot.name !== "") {
                      return (
                        <option
                          value={
                            slot.year +
                            " , " +
                            slot.name +
                            " , " +
                            slot.day +
                            " , " +
                            slot.timeSlot
                          }
                        >
                          {slot.year +
                            " , " +
                            slot.name +
                            " , " +
                            slot.day +
                            " , " +
                            slot.timeSlot}
                        </option>
                      );
                    } else {
                      return (
                        <option
                          value={
                            slot.year + " , " + slot.day + " , " + slot.timeSlot
                          }
                        >
                          {slot.year + " , " + slot.day + " , " + slot.timeSlot}
                        </option>
                      );
                    }
                  })}
              </Form.Select>
            </Form.Group>

            <Button
              variant="primary"
              type="button"
              onClick={() => {
                try {
                  console.log("selected SLOT: " + JSON.stringify(selectedSlot));
                  let tmpSlot = undefined;
                  if (!selectedSlot) {
                    let selVal = slot;
                    let selArray = selVal.split(" , ");
                    let tgtSlot = sessions.find(
                      (slot) =>
                        slot.name === selArray[0] &&
                        slot.day === selArray[1] &&
                        slot.timeSlot === selArray[3]
                    );
                    if (tgtSlot) {
                      tmpSlot = tgtSlot;
                    }
                  } else {
                    tmpSlot = selectedSlot;
                  }
                  if (
                    studentName !== "" &&
                    email !== "" &&
                    phoneNumber !== ""
                  ) {
                    let applicantDetails = {
                      name: studentName,
                      parentName: parentName,
                      email: email,
                      phone: phoneNumber,
                      address: address,
                      referral: referral,
                      status: "PENDING",
                      session: tmpSlot.name,
                      year: tmpSlot.year,
                      day: tmpSlot.day,
                      timeSlot: tmpSlot.timeSlot,
                    };

                    storeToFirebase(applicantDetails, "applicants");
                    let toEmail = "admin@excelmaths.com.au";
                    let emSubject =
                      "New Student Application - " +
                      applicantDetails.year +
                      "|" +
                      applicantDetails.day +
                      "[" +
                      applicantDetails.timeSlot +
                      "]";

                    let emailBody = "<div></div>";
                    emailBody = emailBody + "<table><tbody>";
                    emailBody =
                      emailBody +
                      "<tr><td>Student Name</td><td>" +
                      applicantDetails.name +
                      "</td></tr>";

                    emailBody =
                      emailBody +
                      "<tr><td>Parent Name</td><td>" +
                      applicantDetails.parentName +
                      "</td></tr>";

                    emailBody =
                      emailBody +
                      "<tr><td>Address</td><td>" +
                      applicantDetails.address +
                      "</td></tr>";

                    emailBody =
                      emailBody +
                      "<tr><td>Email</td><td>" +
                      applicantDetails.email +
                      "</td></tr>";

                    emailBody =
                      emailBody +
                      "<tr><td>Phone</td><td>" +
                      applicantDetails.phone +
                      "</td></tr>";

                    emailBody =
                      emailBody +
                      "<tr><td>Year</td><td>" +
                      applicantDetails.year +
                      " " +
                      applicantDetails.session +
                      "</td></tr>";

                    emailBody =
                      emailBody +
                      "<tr><td>Session</td><td>" +
                      applicantDetails.day +
                      " " +
                      applicantDetails.timeSlot +
                      "</td></tr>";

                    emailBody = emailBody + "</tbody></table>";

                    sendEmail(toEmail, emSubject, emailBody);
                    setAlertText(
                      "Thank you for enrolling your child. We will be in touch soon."
                    );
                  } else {
                    setError(true);
                    setAlertText(
                      "Incomplete form. Please fill in all the fields."
                    );
                  }
                  setShow(true);
                } catch (err) {
                  console.log(err);
                }
              }}
            >
              Submit
            </Button>
          </Form>
        </Container>
      </>
    </Container>
  );
};

export default ApplicationForm;
