import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import useGlobalState from "../../Store/configureStore";
import { useNavigate, useLocation } from "react-router-dom";
import Card from "react-bootstrap/Card";
import "./sessions.css";
import { isMobile } from "react-device-detect";
import ImportantDates from "../Apply/ImportantDates";

const SessionsList = () => {
  const sessions = useGlobalState((state) => state.sessions);
  const saveState = useGlobalState((state) => state.saveState);
  const slotDays = useGlobalState((state) => state.slotDays);
  const slotTimes = useGlobalState((state) => state.slotTimes);
  const navigate = useNavigate();

  const getSession = (day, sessionTime) => {
    console.log("checking " + day + "  " + sessionTime);
    let tgtSession = sessions.find((item) => {
      return item.day === day && item.timeSlot === sessionTime;
    });
    if (tgtSession && tgtSession !== undefined) {
      console.log("returning " + JSON.stringify(tgtSession));
      return (
        <td
          style={{
            textAlign: "center",
            justifyContent: "center",
            verticalAlign: "middle",
          }}
        >
          {tgtSession.status === "AVAILABLE" ? (
            <Button
              onClick={(e) => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "instant",
                });
                saveState({ selectedSlot: tgtSession });
                navigate("/apply");
              }}
              variant="link"
            >
              {isMobile
                ? tgtSession.year + "\n" + tgtSession.name
                : tgtSession.year + " " + tgtSession.name}
            </Button>
          ) : (
            <div></div>
          )}
        </td>
      );
    } else {
      console.log("returning empty");
      return <td></td>;
    }
  };

  return (
    <>
      <h1>Click Session to enroll</h1>

      {!isMobile && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <td style={{ textAlign: "center" }}>Session</td>
              {slotDays?.map((item) => (
                <td
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    verticalAlign: "middle",
                  }}
                >
                  {item}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {slotTimes?.map((slot) => {
              return (
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      justifyContent: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    {slot}
                  </td>
                  {slotDays?.map((day) => {
                    return (
                      <td
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        {getSession(day, slot)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      {isMobile && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  verticalAlign: "middle",
                }}
              >
                Day
              </td>
              {slotTimes?.map((sessionTime) => (
                <td
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    verticalAlign: "middle",
                  }}
                >
                  {sessionTime}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {slotDays?.map((day) => {
              return (
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      justifyContent: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    {day.substring(0, 3)}
                  </td>
                  {slotTimes?.map((sessionTime) => {
                    return getSession(day, sessionTime);
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      <h5>
        * These sessions are conducted at The Ponds High School, in room E.21.
      </h5>
    </>
  );
};

export default SessionsList;
