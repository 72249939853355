import React from "react";
import { Button, Overlay } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

const imgStyle = {
  maxHeight: "550px",
  width: "100%",
};

const textStroke = {
  ["-webkit-text-stroke-width"]: "1px",
  ["-webkit-text-stroke-color"]: "black",
  fontWeight: "900",
  letterSpacing: "2px",
};

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <div style={{ border: "0px solid black", minHeight: "230px" }}>
      <Carousel fade interval={3000}>
        <Carousel.Item>
          <Image
            src="/images/h1.png"
            alt="First slide"
            fluid
            style={imgStyle}
          />

          <Carousel.Caption style={{ bottom: "15%" }}>
            {isMobile ? (
              <>
                <h1> Maths Tuition</h1>
                <h4> Year 5 to 12</h4>
              </>
            ) : (
              <>
                <h1> Maths Tuition Year 5 to 12</h1>
                <h4> The Ponds High School</h4>
              </>
            )}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Image
            src="/images/h3.png"
            alt="Third slide"
            fluid
            style={imgStyle}
          />

          <Carousel.Caption style={{ bottom: "15%" }}>
            <h1>HEAD START in MATHS for NEXT YEAR!!</h1>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <Image
            src="/images/h2.png"
            alt="Second slide"
            fluid
            style={imgStyle}
          />
          <Carousel.Caption style={{ bottom: "15%" }}>
            <h1>Limited Spots Available</h1>
            <Button
              onClick={() => {
                navigate("/browse");
              }}
            >
              REGISTER NOW
            </Button>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default HeroSection;
