import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { isMobile } from "react-device-detect";

const Referral = () => {
  return (
    <div style={{ minHeight: "40vh" }}>
      <div style={{ marginTop: "60px" }}></div>
      <h1>The benefits from referrals are as follows:</h1>
      <ListGroup>
        <ListGroupItem>
          Each time your name is mentioned by others via the online enrolment
          form, you will receive a 5% discount on the fee for the following
          term.
        </ListGroupItem>
        <ListGroupItem>
          If your name is referred twice, you will receive a 10% discount on the
          fee for the following term.
        </ListGroupItem>
        <ListGroupItem>
          If your name is referred three times, you will receive a 15% discount
          on the fee for the following term, and so on.
        </ListGroupItem>
      </ListGroup>
    </div>
  );
};

export default Referral;
