import React from "react";
import ColComponent from "./ColComponent";
import Row from "react-bootstrap/Row";
import ListGroup from "react-bootstrap/ListGroup";

const CardSection = () => {
  return (
    <Row xs={1} md={3} className="lg-3">
      <ColComponent
        colkey={0}
        photoLink={"/images/card1.jpg"}
        header={"Our Aim"}
        title={"Our Aim"}
        buttonText="Button"
        onClick={() => {}}
      >
        <ListGroup as="ol">
          <ListGroup.Item as="li" numbered variant="flush">
            To make the learning of mathematics meaningful
          </ListGroup.Item>
          <ListGroup.Item as="li">
            Build confidence in students with maths and guiding them to success
          </ListGroup.Item>
          <ListGroup.Item as="li">
            To inspire and motivate students to be successful in their lives
          </ListGroup.Item>
        </ListGroup>
      </ColComponent>
      <ColComponent
        colkey={1}
        photoLink={"/images/card2.jpg"}
        header={"What we Offer"}
        title={"What we offer"}
        text={
          "Some quick example text to build on the card title and make up the bulk of the card's content."
        }
        buttonText="Button"
        onClick={() => {}}
      >
        <ListGroup as="ol">
          <ListGroup.Item as="li" numbered variant="flush">
            We provide maths tuition to Year 5-12 students
          </ListGroup.Item>
          <ListGroup.Item as="li">
            We develop in-depth knowledge in mathematics
          </ListGroup.Item>
          <ListGroup.Item as="li">
            We build confidence in students to solve HSC-style questions
          </ListGroup.Item>
          <ListGroup.Item as="li">
            We have a valid{" "}
            <span style={{ fontWeight: "bold" }}>
              Working With Children Check
            </span>
          </ListGroup.Item>
        </ListGroup>
      </ColComponent>
      <ColComponent
        colkey={2}
        photoLink={"/images/card3.jpeg"}
        header={"Our approach"}
        title={"Our Approach"}
        buttonText="Button"
        onClick={() => {}}
      >
        <ListGroup as="ol">
          <ListGroup.Item as="li">
            We consider real life applications to make learning mathematics
            meaningful
          </ListGroup.Item>
          <ListGroup.Item as="li">
            We guide students to be independent learners
          </ListGroup.Item>
          <ListGroup.Item as="li">
            We build confidence in students to contribute their thoughts in
            class discussion
          </ListGroup.Item>
        </ListGroup>
      </ColComponent>
    </Row>
  );
};

export default CardSection;
