import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";

const ColComponent = (props) => {
  return (
    <Col key={props.colkey} style={{ marginTop: "60px" }}>
      <Card style={{ minHeight: "540px" }}>
        <Card.Header style={{ fontWeight: "800" }}>
          {props.header.toUpperCase()}
        </Card.Header>
        <Card.Img
          variant="middle"
          src={props.photoLink}
          style={{ maxHeight: "250", objectFit: "contain" }}
        />
        <Card.Body>
          <Card.Text>{props.children}</Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default ColComponent;
