import React from "react";
import Table from "react-bootstrap/Table";
import { isMobile } from "react-device-detect";

const getTermRow = (term, noOfWeeks, date1, date2) => {
  return (
    <tr style={{ textAlign: "center" }}>
      <td colSpan={4}>{term}</td>
      <td colSpan={4}>{noOfWeeks}</td>
      {isMobile && (
        <td colSpan={1}>
          <div>{date1 + " to"}</div>
          <div> {date2}</div>
        </td>
      )}
      {!isMobile && <td colSpan={1}>{date1 + " to " + date2}</td>}
    </tr>
  );
};

const ImportantDates = () => {
  return (
    <>
      <div style={{ marginTop: "60px" }}></div>
      <h1>Session Dates for 2023</h1>
      <Table bordered striped style={{ maxWidth: "800px" }}>
        <tbody>
          {getTermRow(
            "Term 3",
            "4 Weeks",
            "Monday 28-Aug-23",
            "Friday 22-Sep-23"
          )}

          {getTermRow(
            "Term 4",
            "8 Weeks",
            "Monday 09-Oct-23",
            "Friday 15-Dec-23"
          )}
        </tbody>
      </Table>

      <div style={{ marginTop: "30px" }}></div>
      <h1>Session Dates for 2024</h1>
      <Table bordered striped style={{ maxWidth: "800px" }}>
        <tbody>
          {getTermRow(
            "Term 1",
            "10 Weeks",
            "Monday 05-Feb-24",
            "Friday 12-Apr-24"
          )}
          {getTermRow(
            "Term 2",
            "10 Weeks",
            "Monday 29-Apr-24",
            "Friday 05-Jul-24"
          )}
          {getTermRow(
            "Term 3",
            "10 Weeks",
            "Monday 22-Jul-24",
            "Friday 27-Sep-24"
          )}
          {getTermRow(
            "Term 4",
            "10 Weeks",
            "Monday 14-Oct-24",
            "Friday 20-Dec-24"
          )}
        </tbody>
      </Table>
    </>
  );
};

export default ImportantDates;
