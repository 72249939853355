import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { isMobile } from "react-device-detect";

const getSimpleRow = (title, description) => {
  return (
    <tr>
      <td colSpan={2} style={{ fontWeight: "bold" }}>
        {title}
      </td>
      <td colSpan={4}>{description}</td>
    </tr>
  );
};

const OneToOne = () => {
  return (
    <div>
      <div style={{ marginTop: "60px" }}></div>
      <h1>Information on One-to-One Session</h1>
      <Table bordered style={{ maxWidth: "900px" }}>
        <tbody>
          {getSimpleRow("Fees", "$70 per hour")}
          {getSimpleRow(
            "Location",
            "12 Birkdale Street Colebee NSW 2761 OR Online via Zoom"
          )}
          {getSimpleRow("Teacher", "Dr. Goundar")}
          <tr>
            <td colSpan={2} style={{ fontWeight: "bold" }}>
              Availability
            </td>
            <td colSpan={4}>
              <div>Weekends only</div>
              <div>
                <lu>
                  <li style={{ paddingLeft: "15px" }}>
                    • Saturday: Any time after 1 pm
                  </li>
                  <li style={{ paddingLeft: "15px" }}>
                    • Sunday: Any time after 1 pm
                  </li>
                </lu>
              </div>
            </td>
          </tr>
          {getSimpleRow("Arrangements", "Please make arrangements by Fridays.")}
          {getSimpleRow(
            "Flexibility",
            "Time is flexible and based on students' convenience."
          )}
          {getSimpleRow("Payment", "Pay only for the sessions you attend.")}
          {getSimpleRow(
            "Cancellation",
            "No fees will be charged for cancellations."
          )}
          {getSimpleRow(
            "Payment Method",
            "Bulk payment is not required; pay per session."
          )}
          {getSimpleRow(
            "Levels",
            "Tuition is available for all levels of mathematics, including Primary, Secondary, and University."
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default OneToOne;
