import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { isMobile } from "react-device-detect";

const EssayBiography = () => {
  return (
    <Row>
      <Col sm={9}>
        <h1>Dr. Raveen Goundar</h1>
        <p style={{ textAlign: "justify" }}>
          All math tuition will be conducted by Dr. Goundar, a distinguished
          academic and highly experienced math teacher with a proven track
          record of teaching mathematics across Years 7 to 12 in NSW, as well as
          at various universities in Australia.
        </p>
        <p style={{ textAlign: "justify" }}>
          Dr. Goundar's exceptional achievements include receiving gold medals
          in mathematics during both his Bachelor's and Postgraduate studies. He
          holds a PhD in mathematics and has authored numerous scientific
          research papers. His innovative contributions have earned him US
          patents for his inventions. He possesses a rich academic background,
          having studied and worked at esteemed institutions both in Australia
          and abroad.
        </p>
        <p style={{ textAlign: "justify" }}>
          Currently, Dr. Goundar imparts his expertise at a highly reputable
          independent high school, where his exceptional teaching has
          consistently resulted in outstanding HSC math results. All maths
          tuition will be conducted by Dr. Goundar, a distinguished academic and
          a highly experienced maths teacher who has taught mathematics across
          years 7 to 12 in NSW and at universities in Australia.
        </p>
        <p>
          <a href="https://www.linkedin.com/in/raveengoundar/">LinkedIn</a>
        </p>
      </Col>
      <Col sm={3}>
        <Image
          src="/images/drgoundar.jpg"
          style={{ width: "150px", marginTop: isMobile ? "2px" : "60px" }}
        />
      </Col>
    </Row>
  );
};

export default EssayBiography;
