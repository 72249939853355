import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import useGlobalState from "../../Store/configureStore";
import { useNavigate, useLocation } from "react-router-dom";
import Card from "react-bootstrap/Card";
import "./sessions.css";
import { isMobile } from "react-device-detect";
import ImportantDates from "../Apply/ImportantDates";
import SessionsList from "./SessionsList";

const Sessions = () => {
  const sessions = useGlobalState((state) => state.sessions);
  const saveState = useGlobalState((state) => state.saveState);
  const slotDays = useGlobalState((state) => state.slotDays);
  const slotTimes = useGlobalState((state) => state.slotTimes);
  const navigate = useNavigate();

  const getSession = (day, sessionTime) => {
    console.log("checking " + day + "  " + sessionTime);
    let tgtSession = sessions.find((item) => {
      return item.day === day && item.timeSlot === sessionTime;
    });
    if (tgtSession && tgtSession !== undefined) {
      //console.log("returning " + tgtSession.name);
      return (
        <td
          style={{
            textAlign: "center",
            justifyContent: "center",
            verticalAlign: "middle",
          }}
        >
          <Button
            onClick={(e) => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "instant",
              });
              saveState({ selectedSlot: tgtSession });
              navigate("/apply");
            }}
            variant="link"
          >
            {isMobile
              ? tgtSession.year + "\n" + tgtSession.name
              : tgtSession.year + " " + tgtSession.name}
          </Button>
        </td>
      );
    } else {
      console.log("returning empty");
      return <td></td>;
    }
  };

  return (
    <>
      <div style={{ marginTop: "60px" }}></div>
      <SessionsList />
      <ImportantDates />
    </>
  );
};

export default Sessions;
