import { create } from "zustand";
import { getDatabase, ref, onValue, push, set } from "firebase/database";
import _ from "lodash";
const db = getDatabase();
const dayNums = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
};

const useGlobalState = create((zusSet) => ({
  counter: 0,
  authenticated: false,
  slotDay: [],
  slotTimes: [],
  signIn: (username, password) => {
    console.log("signing in");
  },
  signUp: (username, password) => {
    console.log("signing up");
  },
  saveState: (newState) => {
    zusSet({ ...newState });
  },
  storeToFirebase: (record, path) => {
    console.log("record saving to firebase");
    const tableRef = ref(db, path);
    let newRef = push(tableRef);
    record.id = newRef.key;
    set(newRef, record);
  },
  initialSessionLoad: () => {
    console.log("load");
    const staticSesions = require("./initialSessions.json");

    const data = staticSesions;

    if (data && data !== undefined && data !== null) {
      let uArray = Object.keys(data).map((key) => data[key]);
      uArray = uArray.reverse();
      uArray = uArray.map((item) => {
        return { ...item, dayNum: dayNums[item.day] };
      });
      uArray = _.sortBy(uArray, ["dayNum", "timeSlot"]);
      let slotDays = uArray.map((item) => item.day);
      slotDays = _.uniq(slotDays);
      slotDays = _.uniq(slotDays);
      let slotTimes = uArray.map((item) => item.timeSlot);
      slotTimes = _.uniq(slotTimes);
      zusSet({ slotDays: slotDays });
      zusSet({ slotTimes: slotTimes });
      zusSet({ sessions: uArray });
    }
  },
  subscribeToFirebase: (table) => {
    const tableRef = ref(db, table);
    console.log("load");
    onValue(tableRef, (snapshot) => {
      const data = snapshot.val();
      console.log("load complete");
      if (data && data !== undefined && data !== null) {
        let uArray = Object.keys(data).map((key) => data[key]);
        uArray = uArray.reverse();
        uArray = uArray.map((item) => {
          return { ...item, dayNum: dayNums[item.day] };
        });
        uArray = _.sortBy(uArray, ["dayNum", "timeSlot"]);
        let slotDays = uArray.map((item) => item.day);
        slotDays = _.uniq(slotDays);
        slotDays = _.uniq(slotDays);
        let slotTimes = uArray.map((item) => item.timeSlot);
        slotTimes = _.uniq(slotTimes);
        zusSet({ slotDays: slotDays });
        zusSet({ slotTimes: slotTimes });
        zusSet({ [table]: uArray });
      }
    });
  },
  sendEmail: (toEmail, subject, bodyHtml) => {
    bodyHtml = bodyHtml + "<div></div>";
    let urlStr = "https://api.elasticemail.com/v2/email/send?";
    urlStr =
      urlStr +
      "apikey" +
      "=" +
      "2C42B32FB5297C99720F5D4945888B47751791D48E3CFEC2369FEB0EBF402575D9792373F8586DF427E0FCA79B2374AB" +
      "&";
    urlStr = urlStr + "subject" + "=" + subject + "&";
    urlStr = urlStr + "from" + "=" + "admin@excelmaths.com.au" + "&";
    urlStr = urlStr + "to" + "=" + toEmail + "&";
    urlStr = urlStr + "bodyHtml" + "=" + bodyHtml + "&";
    urlStr = urlStr + "bodyText" + "=" + "" + "&";
    urlStr = urlStr + "isTransactional" + "=" + "false";

    fetch(urlStr, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        //this line prints the number of pets received back
        console.log("success");
        return res;
      })
      .catch((err) => {
        console.log("error: " + err);
        return [];
      });
  },
}));

export default useGlobalState;
