import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import "./navstyles.css";

const PublicNav = () => {
  return (
    <Navbar
      expand="lg"
      className="bg-body-tertiary"
      fixed="top"
      collapseOnSelect
      onSelect={(selectedKey) => console.log("check check " + selectedKey)}
      bg="light"
      data-bs-theme="light"
    >
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
            <img src="/images/logo6.png" style={{ height: "50px" }} />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/">
              <Nav.Link className={"navtab"}>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/browse">
              <Nav.Link className={"navtab"}>Sessions</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/importantdates">
              <Nav.Link className={"navtab"}>Important Dates</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/fees">
              <Nav.Link className={"navtab"}>Fees</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/onetoone">
              <Nav.Link className={"navtab"}>One to One</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/location">
              <Nav.Link className={"navtab"}>Location</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/contact">
              <Nav.Link className={"navtab"}>Contact Us</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default PublicNav;
