import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "./Screens/LandingPage/LandingPage";
import ApplicationForm from "./Screens/Apply/ApplicationForm";
import About from "./Screens/About/About";
import Location from "./Screens/Location/Location";
import Sessions from "./Screens/Sessions/Sessions";
import ImportantDates from "./Screens/Apply/ImportantDates";
import Fees from "./Screens/Fees/Fees";
import { Button, Container } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import PublicNav from "./Navigation/PublicNav";
import { useAuthState } from "react-firebase-hooks/auth";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./Firebase/firebaseConn";
import useGlobalState from "./Store/configureStore";
import "./App.css";
import Message from "./Screens/Message/Message";
import OneToOne from "./Screens/OneToOne/OneToOne";
import Referral from "./Screens/Referral/Referral";

const App = () => {
  const [loginError, setLoginError] = React.useState("");
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const dbTables = ["sessions"];
  const subToFb = useGlobalState((state) => state.subscribeToFirebase);
  const sessInit = useGlobalState((state) => state.initialSessionLoad);
  React.useEffect(() => {
    if (user && !loading && !error) {
      dbTables.map((item) => subToFb(item));
    } else {
      console.log("log--");
      signInWithEmailAndPassword(auth, "ahmed_fayyaz@outlook.com", "p123456");
      console.log("log-- completed");
    }
  }, [user]);

  React.useEffect(() => {
    try {
      sessInit();
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <>
      <div id="page-container">
        <div id="content-wrap">
          <Container>
            <PublicNav />
            <div style={{ minHeight: "80px" }}></div>
            <Routes>
              <Route exact path="/" Component={LandingPage} />
              <Route exact path="/home" Component={LandingPage} />
              <Route exact path="/browse" Component={Sessions} />
              <Route exact path="/apply" Component={ApplicationForm} />
              <Route exact path="/contact" Component={About} />
              <Route exact path="/location" Component={Location} />
              <Route exact path="/message" Component={Message} />
              <Route exact path="/fees" Component={Fees} />
              <Route exact path="/onetoone" Component={OneToOne} />
              <Route exact path="/importantdates" Component={ImportantDates} />
              <Route exact path="/referral" Component={Referral} />
              <Route path="*" element={<LandingPage />} />
            </Routes>
          </Container>
        </div>

        <div
          style={{
            border: "0px solid black",
            width: "60px",
            height: "60px",
            borderRadius: "30px",
            textAlign: "center",
            justifyContent: "center",
            verticalAlign: "middle",
            lineHeight: "60px",
            backgroundColor: "gainsboro",
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
            position: "sticky",
            bottom: "10px",
            left: "80%",
            zIndex: "999",
          }}
          onClick={() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "instant",
            });
            navigate("/message");
          }}
        >
          <img
            width="30"
            height="30"
            src="/images/msgIcon2.svg"
            style={{
              margin: "auto",
            }}
          ></img>
        </div>

        <footer>
          <div
            className="footer"
            style={{
              paddingTop: "20px",
              paddingBottom: "30px",
              marginBottom: "0px",
            }}
          >
            <Container>
              <section className="fluidGrid">
                <div style={{ padding: "0px" }}>
                  <h3>Location</h3>
                  <div>The Ponds High School</div>
                  <div>180 Riverbank Drive</div>
                  <div>The Ponds NSW 2769</div>
                  <div></div>
                  <a
                    style={{ color: "white" }}
                    href="https://www.google.com/maps/dir//''/@-33.7032829,150.8152357,12z/data=!4m9!4m8!1m0!1m5!1m1!1s0x6b129ee689fc0abb:0xc37fdb75b36d241f!2m2!1d150.8976379!2d-33.7033103!3e0?entry=ttu"
                    target="_blank"
                  >
                    Get Directions
                  </a>
                </div>
                <div style={{ padding: "0px" }}>
                  <h3>Information</h3>
                  <div>
                    <a
                      href="/importantdates"
                      style={{ color: "white", paddingBottom: "0px" }}
                    >
                      Key Dates
                    </a>
                  </div>
                  <div>
                    <a
                      href="/browse"
                      style={{ color: "white", paddingBottom: "0px" }}
                    >
                      Sessions
                    </a>
                  </div>
                  <div>
                    <a
                      href="/location"
                      style={{ color: "white", paddingBottom: "0px" }}
                    >
                      Location
                    </a>
                  </div>
                </div>
                <div style={{ padding: "0px", paddingBottom: "0px" }}>
                  <h3>Contact Us</h3>
                  <div>Mobile: 042 3871 004</div>
                  <div>Email: admin@excelmaths.com.au</div>
                </div>
              </section>
            </Container>
          </div>
        </footer>
      </div>
    </>
  );
};

export default App;
