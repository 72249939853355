import React from "react";

export const Location = () => {
  return (
    <div style={{ marginTop: "60px" }}>
      <h1>Location </h1>
      <div>
        <div>The Ponds High School</div>
        <div>180 Riverbank Drive</div>
        <div>The Ponds NSW 2769</div>
      </div>
      <br />
      <div>Classes are conducted at The Ponds High School, in room E.21.</div>
      <div style={{ minHeight: "150px" }}></div>
    </div>
  );
};

export default Location;
