import React from "react";
import Table from "react-bootstrap/Table";
import { isMobile } from "react-device-detect";

const Fees = () => {
  return (
    <>
      <div style={{ marginTop: "60px" }}></div>
      <h1>Fees 2023</h1>
      <Table bordered striped style={{ maxWidth: "800px" }}>
        <thead>
          <tr style={{ textAlign: "center" }}>
            <td colSpan={3}>
              <span style={{ fontWeight: "bold" }}>Term</span>
            </td>
            <td colSpan={3}>
              <span style={{ fontWeight: "bold" }}>Per Session</span>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr style={{ textAlign: "center" }}>
            <td colSpan={3}>Term 3</td>
            <td colSpan={3}>$30</td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <td colSpan={3}>Term 4</td>
            <td colSpan={3}>$30</td>
          </tr>
        </tbody>
      </Table>

      <div style={{ marginTop: "60px" }}></div>
      <h1>Fees 2024</h1>
      <Table bordered striped style={{ maxWidth: "800px" }}>
        <thead>
          <tr style={{ textAlign: "center" }}>
            <td colSpan={3}>
              <span style={{ fontWeight: "bold" }}>Term</span>
            </td>
            <td colSpan={3}>
              <span style={{ fontWeight: "bold" }}>Per Session</span>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr style={{ textAlign: "center" }}>
            <td colSpan={3}>Term 1</td>
            <td colSpan={3}>$40</td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <td colSpan={3}>Term 2</td>
            <td colSpan={3}>$40</td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <td colSpan={3}>Term 3</td>
            <td colSpan={3}>$40</td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <td colSpan={3}>Term 4</td>
            <td colSpan={3}>$40</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default Fees;
