import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { isMobile } from "react-device-detect";

const SessionSalePitch = () => {
  return (
    <div>
      <h1>How we conduct sessions?</h1>
      <ListGroup>
        <ListGroupItem>
          To maximise the effectiveness of each session, students are encouraged
          to <b> message the topics they need assistance </b> with, at least a
          day prior to their session.
        </ListGroupItem>
        <ListGroupItem>
          Messages can be sent via email or SMS on mobile.
        </ListGroupItem>
        <ListGroupItem>
          This approach enables us to customize the lessons to target the
          specific areas where students are experiencing difficulties.
        </ListGroupItem>
        <ListGroupItem>
          Our objective is to ensure that by the end of the lesson, students
          have gained confidence in the concepts they were struggling with.
        </ListGroupItem>
      </ListGroup>
    </div>
  );
};

export default SessionSalePitch;
