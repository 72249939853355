import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useNavigate, useLocation } from "react-router-dom";
import useGlobalState from "../../Store/configureStore";

const Message = () => {
  const navigate = useNavigate();
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [alertText, setAlertText] = React.useState({});
  const sendEmail = useGlobalState((state) => state.sendEmail);

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          if (error) {
            setShow(false);
          } else {
            navigate("/");
          }
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Enrollment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <code>{alertText}</code>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              if (error) {
                setShow(false);
              } else {
                navigate("/");
              }
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Container style={{ marginTop: "60px" }}>
        <h4>Hi! Let us know how we can help and we'll respond shortly. </h4>
        <Form>
          <Form.Group className="mb-3">
            <Form.Control
              type="input"
              placeholder="Name*"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Control
              type="input"
              placeholder="Email*"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Control
              as="textarea"
              rows={6}
              placeholder="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Form.Group>

          <Button
            variant="primary"
            type="button"
            onClick={() => {
              try {
                if (name !== "" && email !== "" && message !== "") {
                  let emailBody = "<div></div>";
                  emailBody = emailBody + "<table><tbody>";
                  emailBody =
                    emailBody + "<tr><td>Name</td><td>" + name + "</td></tr>";

                  emailBody =
                    emailBody + "<tr><td>Email</td><td>" + email + "</td></tr>";

                  emailBody =
                    emailBody +
                    "<tr><td>Message</td><td>" +
                    message +
                    "</td></tr>";

                  emailBody = emailBody + "</tbody></table>";

                  sendEmail(
                    "admin@excelmaths.com.au",
                    "Message from " + name,
                    emailBody
                  );
                  setAlertText(
                    "Thanks for sending us a message. We would soon be in touch with you."
                  );
                } else {
                  setError(true);
                  setAlertText(
                    "Incomplete form. Please fill in all the fields."
                  );
                }
                setShow(true);
              } catch (err) {
                console.log(err);
              }
            }}
          >
            Submit
          </Button>
        </Form>
      </Container>
    </>
  );
};

export default Message;
