import React from "react";
import { Button } from "react-bootstrap";

const About = () => {
  return (
    <div style={{ marginTop: "60px" }}>
      <h1>Location </h1>
      <div>
        <div>The Ponds High School</div>
        <div>180 Riverbank Drive</div>
        <div>The Ponds NSW 2769</div>
      </div>
      <br />
      <h4>Contact Us:</h4>
      <div>
        <span style={{ fontWeight: "bold" }}>Mobile:</span> 042 3871 004
      </div>
      <div>
        <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
        admin@excelmaths.com.au
      </div>
      <br />
      <div>
        <div>ABN: 25601096801</div>
      </div>
      <br />
      <Button
        onClick={() => {
          window.open("https://wa.me/+61423871004");
        }}
      >
        Connect on Whatsapp
      </Button>
    </div>
  );
};

export default About;
