import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import HeroSection from "./HeroSection";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../Firebase/firebaseConn";
import CardSection from "./CardSection";
import EssayBiography from "./EssayBiography";
import { isMobile } from "react-device-detect";
import SessionsList from "../Sessions/SessionsList";
import OneToOne from "../OneToOne/OneToOne";
import SessionSalePitch from "../Sessions/SessionSalesPitch";

const LandingPage = () => {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  return (
    <>
      <Container fluid>
        <HeroSection />
        <div style={{ minHeight: "60px" }}></div>
        <SessionsList />
        <div style={{ minHeight: "60px" }}></div>
        <SessionSalePitch />
        <div style={{ minHeight: "60px" }}></div>
        <EssayBiography />
        <CardSection />
      </Container>
      <div style={{ minHeight: "50px" }}></div>
    </>
  );
};

export default LandingPage;
