import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD2URfjDXt6hOcvfdPbRKa5hv9wafPW-L4",
  authDomain: "tutoringwebsite-e5bf3.firebaseapp.com",
  projectId: "tutoringwebsite-e5bf3",
  databaseURL:
    "https://tutoringwebsite-e5bf3-default-rtdb.asia-southeast1.firebasedatabase.app/",
  storageBucket: "tutoringwebsite-e5bf3.appspot.com",
  messagingSenderId: "428230684667",
  appId: "1:428230684667:web:6022b264cb082e3ba9a55e",
  measurementId: "G-979FG8LYX5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// const starCountRef = ref(db, "cities");
// onValue(starCountRef, (snapshot) => {
//   data = snapshot.val();
// });

export const auth = getAuth(app);
